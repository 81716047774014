import React from 'react';

import Layout from '../components/Layout';

import Scroll from '../components/Scroll';

import config from '../../config';
import pic1 from   '../assets/images/alberto-ramentol-noria.jpg';
import pic2 from   '../assets/images/marta-vidal-florejachs.jpg';
import pic3 from   '../assets/images/cristina-ramentol-sabate.jpg';

const images = {pic1 , pic2 , pic3}

const IndexPage = () => (
  <Layout>
    <section id="banner">
      <div className="inner">
        <h2>{config.home_heading}</h2>
        <p>{config.home_subHeading}</p>
        <p className="subText">{config.home_subTitleTxt}</p>
      </div>
      <Scroll type="id" element="presentacion">
        <a href="#presentacion" className="more">{config.home_readMoreTxt}</a>
      </Scroll>
    </section>

    <section id="presentacion" className="wrapper style1 special">
      <div className="inner">
        <header className="major">
          <h2>{config.menuItems[1].name}</h2>
            <p>{config.home_s1_p1}</p>
            <p>{config.home_s1_p2}</p>
            <p>{config.home_s1_p3}</p>
            <p>{config.home_s1_p4}</p>
            <h2>{config.menuItems[2].name}</h2>
        </header>
      </div>
    </section>

    <section id="equipo" className="wrapper alt style2">
      <section className="spotlight">
        <div className="image">
          <img src={pic1} alt="{config.team[0].member}" />
        </div>
        <div className="content">
          <h2>{config.team[0].member}</h2>
          <p>{config.team[0].presentation}</p>
          <ul className="icons">
            <li><a href={config.team[0].mail} className="icon solid fa-envelope" target="_blank"><span className="label">{config.team[1].mail.slice(0)}</span></a></li>
          </ul>
        </div>
      </section>
      <section className="spotlight">
        <div className="image">
          <img src={pic2} alt="{config.team[1].member}" />
        </div>
        <div className="content">
          <h2>{config.team[1].member}</h2>
          <p>{config.team[1].presentation}</p>
          <ul className="icons">
            <li><a href={config.team[1].linkedin} className="icon brands fa-linkedin" target="_blank"><span className="label">LinkedIn {config.team[1].member}</span></a></li>
            <li><a href={config.team[1].mail} className="icon solid fa-envelope" target="_blank"><span className="label">{config.team[1].mail.slice(7)}</span></a></li>
          </ul>
        </div>
      </section>
      <section className="spotlight">
        <div className="image">
          <img src={pic3} alt="{config.team[2].member}" />
        </div>
        <div className="content">
          <h2>{config.team[2].member}</h2>
          <p>{config.team[2].presentation}</p>
          <ul className="icons">
            <li><a href={config.team[2].linkedin} className="icon brands fa-linkedin" target="_blank"><span className="label">LinkedIn {config.team[2].member}</span></a></li>
            <li><a href={config.team[2].mail} className="icon solid fa-envelope" target="_blank"><span className="label">{config.team[2].mail.slice(7)}</span></a></li>
          </ul>
        </div>
      </section>
    </section>

    <section id="partidos-judiciales" className="wrapper style3 special">
      <div className="inner">
        <header className="major">
          <h2>{config.menuItems[3].name}</h2>
          <p>{config.partidos_subHeading}</p>
        </header>
        <ul className="features">
          {config.partidos.map(partido => {
            const { name } = partido;
            return (
            <li className={`${'icon fa-map-location'}`}>
              <h3>{name}</h3>
              <p> </p>
            </li>
            );
          })}
        </ul>
      </div>
    </section>

    <section id="cta" className="wrapper style4">
      <div className="inner">
        <header>
          <h2>{config.menuItems[4].name}</h2>
          <p>{config.services_subHeading}</p>
        </header>
        <ul className="actions stacked">
          <li>
            <a href="/Servicios" className="button fit">{config.services_call2action}</a>
          </li>
        </ul>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
